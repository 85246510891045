
















































































































import Vue from "vue";
import postFormSuporte from "@/api/requests/postFormSuporte";
import getEventsToSupport from "@/api/requests/getEventsToSupport";

import TTextInput from "@/components/core/TTextInput.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import TSelect from "@/components/core/TSelect.vue";
import TCard from "@/components/core/TCard.vue";
import { DateTime } from "luxon";

export default Vue.extend({
  name: "SupportPage",

  components: {
    TTextInput,
    TCpfInput,
    TSelect,
    TCard,
  },

  data: () => ({
    loading: false,
    form: {
      nome: "",
      email: "",
      documento: "",
      jogo_evento: "",
      assunto_id: 0,
      num_pedido: "",
      mensagem: "",
      telefone: "",
    },
    jogos_eventos: [{ label: "Outros Eventos", id: "1" }],
    assuntos: [
      { label: "Acesso aos Meus Ingressos", id: 30 },
      { label: "Informações sobre o Jogo/Evento", id: 31 },
      { label: "Login e Senha incorretos", id: 32 },
      { label: "Atualização de Cadastro", id: 33 },
      { label: "Pagamento de Ingresso", id: 34 },
      { label: "Cancelamento de Ingresso", id: 35 },
      { label: "Transferência de Ingresso", id: 36 },
      { label: "Outros", id: 6 },
    ],
    captcha_key: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}`,
  }),
  mounted() {
    window.scrollTo(0, 0);
    this.getEventos();
  },
  computed: {
    formValidate(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
  },
  methods: {
    async getEventos() {
      const eventos = await getEventsToSupport();
      eventos.data.map((evt) => {
        const startDate = DateTime.fromFormat(evt.inicio, "yyyy-MM-dd HH:mm:ss");
        this.jogos_eventos.unshift({
          id: evt.id,
          label: `${startDate.toFormat("dd/MM/yyyy")} | ${evt.nome} - ${evt.competicao} `,
        });
      });
    },
    async submitForm() {
      this.loading = true;
      if (this.form.nome.length && /\d/.test(this.form.nome)) {
        this.$snackbar("O campo nome não pode conter números.", "error");
      } else {
        if (this.formValidate.validate()) {
          await this.$recaptchaLoaded();

          const token = await this.$recaptcha("submit");
          let adicionais = {};
          if (this.form.jogo_evento != "") {
            adicionais = {
              evento: this.jogos_eventos.find((evt) => evt.id == this.form.jogo_evento)?.label ?? this.form.jogo_evento,
              pedido: this.form.num_pedido,
              documento: this.form.documento,
              telefone: this.form.telefone,
            };
          }

          try {
            await postFormSuporte({
              nome: this.form.nome,
              email: this.form.email,
              assunto_id: this.form.assunto_id,
              mensagem: this.form.mensagem,
              adicionais: adicionais,
              token: token,
            });
            this.$snackbar("Formulário de contato enviado com sucesso.", "success");
          } catch (e) {
            this.$snackbar("Erro! Tente novamente mais tarde.", "error");
          }
          this.form.mensagem = "";
          (this.$refs.form as HTMLFormElement).reset();
        } else {
          this.$snackbar("Verifique todos os campos.", "error");
        }
      }
      this.loading = false;
    },
  },
});
