import api from "@/api";
import DataResponse from "@/types/DataResponse";
import { AxiosResponse } from "axios";

interface Params {
  nome: string;
  email: string;
  assunto_id: number;
  mensagem: string;
  token: string; // captcha validation
  adicionais: { [key: string]: string };
}

export default async function postFormSuporte(form: Params): Promise<AxiosResponse<DataResponse<unknown>>> {
  return await api.post(`/api/contact`, form);
}
